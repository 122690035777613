import { Container, Row, Col, Card } from "react-bootstrap";
import "../css/about.css";

import tk from "../datas/about.json";

const AboutComponent = () => {
  const { tentang_kami } = tk;

  return (
    <Container
      className="my-5 tentang-kami padding-tb50"
      id="tentang-kami"
      style={{ scrollMarginTop: "50px" }}
    >
      <Row>
        <Col md={6}>
          <div className="left-content">
            <h2 className="secondary-color bolder">{tentang_kami.judul}</h2>
            <p>{tentang_kami.deskripsi}</p>
            <p>{tentang_kami.keterangan}</p>
          </div>
        </Col>
        <Col md={6}>
          <div className="right-content">
            <Card className="p-4">
              <Card.Title className="secondary-color bolder">
                Nilai Perusahaan
              </Card.Title>
              <Card.Body>
                <ul>
                  {tentang_kami.nilai_perusahaan.map((nilai, index) => (
                    <li key={index}>
                      <span style={{ marginRight: "10px" }}>
                        <img
                          src={require("../images/" + nilai.icon)}
                          alt={nilai.title}
                        />
                      </span>
                      {nilai.title}
                    </li>
                  ))}
                </ul>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutComponent;
