import { Container, Row, Col, Card } from "react-bootstrap";
import "../css/cars.css";

import cars from "../datas/cars.json";

const CarComponent = () => {
  return (
    <div
      className="etalase padding-tb50"
      id="armada"
      style={{ scrollMarginTop: "50px" }}
    >
      <Container>
        <Row className="margin-b50">
          <Col>
            <h2 className="bolder">Pilihan Mobil Sewaan Terbaik</h2>
            <p>
              Temukan Ragam Pilihan Mobil Berkualitas dengan Berbagai Fasilitas
            </p>
          </Col>
        </Row>
        <Row>
          {cars.map((dt, i) => (
            <Col key={i} xs={12} md={3} lg={3}>
              <Card className="minimal-card">
                <div className="img-container">
                  <Card.Img
                    variant="top"
                    src={require("../images/" + dt.gambar)}
                    alt={dt.gambar}
                  />
                </div>
                <Card.Body>
                  <div className="top">
                    <Card.Title className="bolder">
                      {dt.merk + " " + dt.tipe}
                    </Card.Title>
                    <Card.Text className="price">{dt.harga}</Card.Text>
                  </div>
                  <div className="bottom">
                    <Card.Text className="desc">{dt.deskripsi}</Card.Text>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default CarComponent;
