import { Container, Row, Col } from "react-bootstrap";

import "../css/footer.css";

import l from "../datas/services.json";
import contact from "../datas/contact.json";
import navigasi from "../datas/navigasi.json";

const FooterComponent = () => {
  const { informasi_kontak, media_sosial } = contact.kontak_kami;

  const { layanan } = l;

  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md={3} xs={12}>
            <h4 className="bolder t-left">Informasi Kontak</h4>
            <div className="divider center" />
            <Row>
              <Col xs={2} md={3} lg={2}>
                <span>
                  <img
                    src={require("../images/address.svg").default}
                    alt={"address"}
                    style={{ filter: "invert(1)" }}
                  />
                </span>
              </Col>
              <Col
                xs={10}
                md={9}
                lg={10}
                style={{ textAlign: "left", paddingLeft: "0" }}
              >
                {informasi_kontak.alamat_kantor}
              </Col>
            </Row>
            <Row>
              <Col xs={2} md={3} lg={2}>
                <span>
                  <img
                    src={require("../images/mail.svg").default}
                    alt={"address"}
                    style={{ filter: "invert(1)" }}
                  />
                </span>
              </Col>
              <Col
                xs={10}
                md={9}
                lg={10}
                style={{ textAlign: "left", paddingLeft: "0" }}
              >
                {informasi_kontak.email}
              </Col>
            </Row>
            <Row>
              <Col xs={2} md={3} lg={2}>
                <span>
                  <img
                    src={require("../images/phone-fill.svg").default}
                    alt={"address"}
                    style={{ filter: "invert(1)" }}
                  />
                </span>
              </Col>
              <Col
                xs={10}
                md={9}
                lg={10}
                style={{ textAlign: "left", paddingLeft: "0" }}
              >
                {informasi_kontak.telepon}
              </Col>
            </Row>
          </Col>
          <Col md={3} xs={12}>
            <h4 className="bolder t-left">Menu Navigasi</h4>
            <div className="divider" />
            <ul>
              {navigasi.map((dt, i) => (
                <li key={i}>
                  <a href={dt.link}>{dt.title}</a>
                </li>
              ))}
            </ul>
          </Col>
          <Col md={3} xs={12}>
            <h4 className="bolder t-left">Layanan Kami</h4>
            <div className="divider" />
            <ul>
              {layanan.detail_layanan.map((item, index) => (
                <li key={index}>{item.judul}</li>
              ))}
            </ul>
          </Col>
          <Col md={3} xs={12}>
            <h4 className="bolder t-left">Media Sosial</h4>
            <div className="divider" />
            <ul>
              {Object.entries(media_sosial).map(([key, value]) => (
                <li key={key}>
                  <a
                    href={value}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button-ig"
                  >
                    <img
                      src={require("../images/ig.svg").default}
                      alt={"address"}
                      style={{ filter: "invert(1)", width: "5rem" }}
                    />
                  </a>
                </li>
              ))}
            </ul>
          </Col>
          {/* <Col md={3} xs={12}>
            <h4 className="bolder t-left">Alamat Kami</h4>
            <div className="divider" />
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe
                  title="Google Maps"
                  width="100%"
                  height="150"
                  id="gmap_canvas"
                  src={maps}
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                ></iframe>
              </div>
            </div>
          </Col> */}
        </Row>
        <hr />
        <Row>
          <Col>
            <p className="text-center">
              &copy; {new Date().getFullYear()} YA Rent Car
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="text-center">
              Dikembangkan oleh{" "}
              <a
                href="http://brocyber.dev"
                target="blank"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                Brocyber
              </a>
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="text-center">
              <a
                href="https://adsboss.id"
                target="blank"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                PT. BOSS DIGITAL TEKNOLOGI
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default FooterComponent;
