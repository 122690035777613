import { Container, Row, Col, Button } from "react-bootstrap";
import "../css/banner.css";
import anime from "animejs";
import React, { useEffect, useRef } from "react";

import banner from "../datas/banner.json";
import contact from "../datas/contact.json";
import AnimationTyping from "./animation";

const HeaderComponent = ({ setShowModal }) => {
  const { informasi_kontak } = contact.kontak_kami;

  return (
    <div className="header-section padding-tb50" id="beranda">
      <div className="overlay"></div>
      <Container>
        <Row>
          <Col md={6} className="hc-container">
            <div className="header-content">
              <h1>
                {banner.header.judul + " "}
                <br />
                <span
                  class="bold-text secondary-color"
                  style={{ fontSize: "5rem !important" }}
                >
                  <AnimationTyping data={banner.header.juduls} />
                  <br />
                  {banner.header.judul2}
                </span>
              </h1>
              <p>{banner.header.tagline}</p>
              {/* <p>
                <span className="bold-text ft-3rem">Rp250</span>.000 /hari
              </p> */}
              <Button
                className="btn-whatsapp"
                href={informasi_kontak.wa}
                target="_blank"
              >
                Hubungi kami
              </Button>
              <Button
                variant="gradient"
                className="booking-button"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Booking
              </Button>
            </div>
          </Col>
          <Col md={6} className="hc-container">
            <div className="header-image">
              <img
                src={require("../images/" + banner.header.image)}
                alt="Gambar Perusahaan"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeaderComponent;
