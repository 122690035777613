import React, { useState } from "react";
import {
  Modal,
  Button,
  Form,
  FormGroup,
  FormControl,
  FormLabel,
  Dropdown,
  Alert,
  Row,
  Col,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import emailjs from "emailjs-com";
import { format, parse } from "date-fns";
import { id } from "date-fns/locale";

import cars from "../datas/cars.json";

import "../css/form.css";

const FormModal = ({ show, setShow }) => {
  const [formData, setFormData] = useState({
    namaPemesan: "",
    nomor: "",
    tanggalMulai: "",
    tanggalAkhir: "",
    unitKendaraan: "",
    pesan: "",
  });

  const [validationError, setValidationError] = useState({
    namaPemesan: "",
    nomor: "",
    tanggalMulai: "",
    tanggalAkhir: "",
    unitKendaraan: "",
    pesan: "",
  });

  const handleInputChange = (e) => {
    setValidationError("");
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const formatDateToIndonesian = (date) => {
    date = new Date(date);
    return format(date, "EEEE, dd MMMM yyyy", { locale: id });
  };

  const handleStartDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      tanggalMulai: date,
    }));
  };

  const handleEndDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      tanggalAkhir: date,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validasi form sebelum pengiriman
    let isValid = true;
    const newValidationError = { ...validationError };

    Object.keys(formData).forEach((key) => {
      if (formData[key] === "") {
        newValidationError[key] = "Field ini harus diisi.";
        isValid = false;
      } else {
        newValidationError[key] = "";
      }
    });

    if (!isValid) {
      setValidationError(newValidationError);
      return;
    }

    sendEmail(e);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    var templateParams = {
      Nama: formData.namaPemesan,
      Nomor: formData.nomor,
      TanggalMulai: formatDateToIndonesian(formData.tanggalMulai),
      TanggalSelesai: formatDateToIndonesian(formData.tanggalAkhir),
      UnitKendaraan: formData.unitKendaraan,
      Pesan: formData.pesan,
    };

    emailjs
      .send(
        "service_wxw4tlq",
        "template_d4fcplc",
        templateParams,
        "79SC7-YkSH178jVwS"
      )
      .then(
        (result) => {
          alert("Email terkirim dengan sukses!");
          setShow(false);
          setFormData("");
        },
        (error) => {
          alert("Terjadi kesalahan saat mengirim email. Silakan coba lagi.");
          setShow(false);
          setFormData("");
        }
      );
  };

  const closeModal = () => {
    setValidationError("");
    setShow(false);
    setFormData("");
  };

  return (
    <Modal className="form-modal" show={show} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Form Pemesanan</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <FormGroup>
            <FormLabel>Nama</FormLabel>
            <FormControl
              type="text"
              name="namaPemesan"
              value={formData.namaPemesan}
              onChange={handleInputChange}
              placeholder="Hasan Haykal"
            />
            {validationError.namaPemesan && (
              <Alert variant="danger">{validationError.namaPemesan}</Alert>
            )}
          </FormGroup>

          <FormGroup>
            <FormLabel>Nomor Telepon</FormLabel>
            <FormControl
              type="text"
              name="nomor"
              value={formData.nomor}
              onChange={handleInputChange}
              placeholder="081287498378"
            />
            {validationError.nomor && (
              <Alert variant="danger">{validationError.nomor}</Alert>
            )}
          </FormGroup>

          <Row>
            <Col xs={12} md={6}>
              <FormGroup>
                <FormLabel>Mulai</FormLabel>
                <DatePicker
                  name="tanggalMulai"
                  value={formData.tanggalMulai}
                  selected={formData.tanggalMulai}
                  onChange={handleStartDateChange}
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="01/01/2024"
                />
                {validationError.tanggalMulai && (
                  <Alert variant="danger">{validationError.tanggalMulai}</Alert>
                )}
              </FormGroup>
            </Col>

            <Col xs={12} md={6}>
              <FormGroup>
                <FormLabel>Akhir</FormLabel>
                <DatePicker
                  name="tanggalAkhir"
                  value={formData.tanggalAkhir}
                  selected={formData.tanggalAkhir}
                  onChange={handleEndDateChange}
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="04/01/2024"
                />
                {validationError.tanggalAkhir && (
                  <Alert variant="danger">{validationError.tanggalAkhir}</Alert>
                )}
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <FormLabel>Unit Kendaraan</FormLabel>
            <Form.Control
              as="select"
              name="unitKendaraan"
              value={formData.unitKendaraan}
              onChange={handleInputChange}
            >
              <option value="">Pilih Kendaraan</option>
              {cars.map((option, i) => (
                <option
                  key={i}
                  value={`${option.merk} ${option.tipe}`}
                >{`${option.merk} - ${option.tipe}`}</option>
              ))}
            </Form.Control>
            {validationError.unitKendaraan && (
              <Alert variant="danger">{validationError.unitKendaraan}</Alert>
            )}
          </FormGroup>

          <FormGroup>
            <FormLabel>Pesan</FormLabel>
            <FormControl
              as="textarea"
              rows={3}
              name="pesan"
              value={formData.pesan}
              onChange={handleInputChange}
              placeholder="Saya ingin menyewa tanpa supir"
            />
            {validationError.pesan && (
              <Alert variant="danger">{validationError.pesan}</Alert>
            )}
          </FormGroup>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => closeModal()}>
          Tutup
        </Button>
        <Button variant="primary" onClick={(e) => handleSubmit(e)}>
          Kirim
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FormModal;
