import { Container, Row, Col, Card } from "react-bootstrap";
import { BsStarFill } from "react-icons/bs";
import { Slide } from "react-slideshow-image";

import "../css/testi.css";

import testimoni from "../datas/testi.json";

const TestiComponent = () => {
  const renderStars = (rating) => {
    const stars = [];
    for (let i = 0; i < rating; i++) {
      stars.push(<BsStarFill key={i} color="#ffc107" />);
    }
    return stars;
  };

  const responsiveSettings = [
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 300,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ];

  return (
    <section
      id="testimoni"
      className="testimonial-section padding-tb50"
      style={{ scrollMarginTop: "50px" }}
    >
      <div className="overlay"></div>
      <Container>
        <Row className="margin-b30">
          <Col>
            <h2 className="bolder">Suara Pelanggan: Kisah Kepuasan</h2>
            <p>
              Testimoni Langsung dari Pelanggan yang Mempercayai Layanan Kami
            </p>
          </Col>
        </Row>
        <Row>
          {testimoni.comment.slice(0, 4).map((testimonial) => (
            <Col key={testimonial.id} md={3} className="card-container">
              <Card>
                <Card.Body>
                  <div className="testimonial-top">
                    <div className="testimonial-info">
                      <p className="testimonial-name">{testimonial.nama}</p>
                    </div>
                    <div className="testimonial-rating">
                      {renderStars(testimonial.bintang)}
                    </div>
                  </div>
                  <div className="testimonial-bottom">
                    <p className="testimonial-text">{testimonial.pesan}</p>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <Slide
          slidesToScroll={1}
          slidesToShow={5}
          indicators={true}
          responsive={responsiveSettings}
        >
          {testimoni.images.map((dt, key) => (
            <div className="img-slider" key={key}>
              <img src={require("../images/testimoni/" + dt)} alt={dt} />
            </div>
          ))}
        </Slide>
      </Container>
    </section>
  );
};

export default TestiComponent;
