import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-slideshow-image/dist/styles.css";

import { useState, useEffect } from "react";

import NavigationComponent from "./components/navigasi.js";
import FormModal from "./components/formModal.js";

import HeaderComponent from "./components/banner.js";
import AboutComponent from "./components/about.js";
import CarComponent from "./components/cars.js";
import ServicesComponent from "./components/services.js";
import TestiComponent from "./components/testi.js";
import ContactComponent from "./components/contact.js";
import FooterComponent from "./components/footer.js";
import FloatingButton from "./components/floatingButton.js";

function App() {
  const [scrolling, setScrolling] = useState(false);

  const [activeLink, setActiveLink] = useState("#beranda");

  const [showModal, setShowModal] = useState(false);

  const handleNavLinkClick = (title) => {
    setActiveLink(title);
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 100;

    if (window.scrollY > 0) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }

    const berandaSection = document.getElementById("beranda");
    if (berandaSection && scrollPosition >= berandaSection.offsetTop) {
      setActiveLink("#beranda");
    }

    const tentangKamiSection = document.getElementById("tentang-kami");
    if (tentangKamiSection && scrollPosition >= tentangKamiSection.offsetTop) {
      setActiveLink("#tentang-kami");
    }

    const armadaSection = document.getElementById("armada");
    if (armadaSection && scrollPosition >= armadaSection.offsetTop) {
      setActiveLink("#armada");
    }

    const layananSection = document.getElementById("layanan");
    if (layananSection && scrollPosition >= layananSection.offsetTop) {
      setActiveLink("#layanan");
    }

    const kontakKamiSection = document.getElementById("kontak-kami");
    if (kontakKamiSection && scrollPosition >= kontakKamiSection.offsetTop) {
      setActiveLink("#kontak-kami");
    }
  };

  useEffect(() => {
    const hash = window.location.hash;
    console.log(hash);
    setActiveLink(hash || "#beranda");

    if (window.scrollY > 0) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }

    if (hash && document.querySelector(hash)) {
      const section = document.querySelector(hash);
      section.scrollIntoView({ behavior: "smooth" });
    }

    setShowModal(false);

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="App">
      <FormModal
        show={showModal}
        setShow={(e) => {
          setShowModal(e);
        }}
      />

      <NavigationComponent
        scrolling={scrolling}
        activeLink={activeLink}
        handleNavLinkClick={handleNavLinkClick}
        setShow={(e) => {
          setShowModal(e);
        }}
      />

      <HeaderComponent
        setShowModal={(e) => {
          setShowModal(e);
        }}
      />

      <AboutComponent />

      <CarComponent />

      <ServicesComponent />

      <TestiComponent />

      <ContactComponent />

      <FooterComponent />

      <FloatingButton />
    </div>
  );
}

export default App;
