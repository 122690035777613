import { Container, Row, Col } from "react-bootstrap";
import "../css/service.css";

import l from "../datas/services.json";

const ServicesComponent = () => {
  const { layanan } = l;

  return (
    <div
      className="layanan service-section padding-tb50"
      id="layanan"
      style={{ scrollMarginTop: "50px" }}
    >
      <Container>
        <Row className="margin-b50">
          <Col>
            <h2 className="bolder">{layanan.judul}</h2>
            <p>{layanan.deskripsi}</p>
          </Col>
        </Row>
        <Row>
          {layanan.detail_layanan.map((item, index) => (
            <Col key={index} md={6} className="padding-lr50">
              <div className="center-container">
                <div className="icon-container">
                  <img
                    src={require("../images/" + item.icon)}
                    alt={item.judul}
                  />
                </div>
              </div>
              <h3 className="bolder">{item.judul}</h3>
              <p className="text-align-justify">{item.deskripsi}</p>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default ServicesComponent;
