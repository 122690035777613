import { FaWhatsapp } from "react-icons/fa";

import contact from "../datas/contact.json";

const FloatingButton = () => {
  const { informasi_kontak } = contact.kontak_kami;

  return (
    <div className="floating-button">
      <a href={informasi_kontak.wa} target="_blank" rel="noopener noreferrer">
        <FaWhatsapp className="whatsapp-icon" />
      </a>
    </div>
  );
};

export default FloatingButton;
