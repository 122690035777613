import { TypeAnimation } from "react-type-animation";

const AnimationTyping = ({ data }) => {
  return (
    <TypeAnimation
      className="animationType"
      sequence={data}
      wrapper="span"
      speed={50}
      repeat={Infinity}
    />
  );
};

export default AnimationTyping;
