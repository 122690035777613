import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";

import "../css/contact.css";

import contact from "../datas/contact.json";

const ContactComponent = () => {
  const {
    judul,
    deskripsi,
    informasi_kontak,
    jam_operasional,
    media_sosial,
    maps,
  } = contact.kontak_kami;

  return (
    <section
      className="contact-section py-5"
      id="kontak-kami"
      style={{ scrollMarginTop: "50px" }}
    >
      <Container>
        <h2 className="bolder">{judul}</h2>
        <p>{deskripsi}</p>
        {/* <Row>
          <Col xs={12} sm={4}>
            <Card className="mb-4">
              <Card.Body>
                <h4 className="bolder">Informasi Kontak</h4>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <strong>Alamat Kantor:</strong>{" "}
                    {informasi_kontak.alamat_kantor}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Telepon:</strong> {informasi_kontak.telepon}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Email:</strong> {informasi_kontak.email}
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={4}>
            <Card className="mb-4">
              <Card.Body>
                <h4 className="bolder">Jam Operasional</h4>
                <ListGroup variant="flush">
                  {Object.entries(jam_operasional).map(([key, value]) => (
                    <ListGroup.Item key={key}>
                      <strong>{key}:</strong> {value}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={4}>
            <Card className="mb-4">
              <Card.Body>
                <h4 className="bolder">Media Sosial</h4>
                <ListGroup variant="flush">
                  {Object.entries(media_sosial).map(([key, value]) => (
                    <ListGroup.Item key={key}>
                      <a href={value} target="_blank" rel="noopener noreferrer">
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                      </a>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row> */}
        <Row>
          <Col xs={12}>
            <iframe
              title="Google Maps"
              width="100%"
              height="400"
              frameBorder="0"
              style={{ border: 0 }}
              src={maps}
              allowFullScreen
            ></iframe>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactComponent;
