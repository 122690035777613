import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Navbar, Nav, Button } from "react-bootstrap";
import "../css/header.css"; // File CSS kustom untuk styling tambahan

import navigasi from "../datas/navigasi.json";

const NavigationComponent = ({
  scrolling,
  activeLink,
  handleNavLinkClick,
  setShow,
}) => {
  return (
    <div className="navigation">
      <Navbar
        expand="lg"
        fixed="top"
        className={
          scrolling ? "navbar-scroll scrolling-active" : "navbar-scroll"
        }
        collapseOnSelect
        style={{ display: "flex", alignItems: "center" }}
      >
        <Container className="padding-0">
          <Navbar.Brand href="#home">
            <img
              src={require("../images/logo.png")}
              width="30"
              height="30"
              className="d-inline-block align-top brand-logo"
              alt="YA Rent Car"
            />
            YA Rent Car
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="mr-auto">
              {navigasi.map((dt, i) => (
                <Nav.Link
                  key={i}
                  href={dt.link}
                  active={activeLink === dt.link}
                  onClick={() => handleNavLinkClick(dt.link)} // Mengatur link yang aktif saat Nav.Link diklik
                >
                  {dt.title}
                </Nav.Link>
              ))}
            </Nav>
            <Button
              variant="gradient"
              className="booking-button"
              onClick={() => {
                setShow(true);
              }}
            >
              Booking
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavigationComponent;
